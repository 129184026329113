<template>
    <Header></Header>
    <router-view />
    <EliteuServiceVue3
        :chatH5Style="{ right: '2vw', bottom: '5vw' }"
        :chatStyle="{ bottom: '72px' }"
        :title="serviceTitle"
    />
    <!-- <p class="link" v-if="showLink">
        <a
            href="https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/2fe350911397757900408812687/v.f40673.mp4"
            target="_blank"
            >图形化编程作品平台入门教程
        </a>
    </p> -->
    <div class="bottom-desc" v-if="showBottomTip">
        <div class="link" v-if="showLink">
            <div class="course">
                <div class="tooltip" v-if="isFirst">
                    <div class="content">
                        <div class="arrow"></div>
                        <div class="inner" role="tooltip">
                            <span>首次使用这个产品吗？先看看教程吧</span>
                        </div>
                    </div>
                </div>
                <span @click="openCourse" class="course">产品入门教程</span>
            </div>

            <span class="line"></span>
            <span>产品版本：{{ version }}</span>
        </div>
        <p>
            图形化编程作品平台内含大量英荔优秀学员过往的作品改编而成的互动体验游戏，
            是英荔「先体验、再改编、后创造、能分享」自驱力学习法的核心体现
        </p>
    </div>
</template>
<script lang="ts">
import {
    ref,
    defineComponent,
    onBeforeMount,
    watch,
    onMounted,
    reactive,
} from 'vue';
import Header from '@/components/Header.vue';
import store from './store';
import { useRoute } from 'vue-router';
import router from './router';
import { EliteuServiceVue3 } from '@aimakerspace/eliteu-service-vue3';
import { useVisitRecord } from '@/utils';

import { Campus } from '@/types/category';
export default defineComponent({
    name: 'HomeView',
    components: {
        Header,
        EliteuServiceVue3,
    },
    setup() {
        const route = useRoute();
        const serviceTitle = ref('111');
        const campusInfo = reactive<Campus>(store.state.campusInfo);
        const { has: hasVisitRecord, save: saveVisitRecord } = useVisitRecord();
        const replace = () => {
            if (window.innerWidth < 601) {
                if (route.name !== 'mobile') {
                    router.replace({
                        name: 'mobile',
                    });
                }
            } else {
                if (route.name === 'mobile') {
                    router.replace({
                        name: 'category',
                    });
                }
            }
        };
        const showLink = ref(false);
        const showBottomTip = ref(false);
        const version = ref('');
        const curStoreId = ref(null);

        const isFirst = ref(false);
        const openCourse = () => {
            curStoreId.value && saveVisitRecord(curStoreId.value);
            isFirst.value = false;
            window.open(
                'https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/2fe350911397757900408812687/v.f40673.mp4',
                '_blank'
            );
        };
        onBeforeMount(() => {
            const isLogin = sessionStorage.getItem('isLogin');
            if (!isLogin) {
                store.commit('updateInfo', {
                    store_id: null,
                    name: '',
                    short_name: '',
                    version: '',
                });
            }
        });

        onMounted(() => {
            if (window.location.pathname.includes('/blocks-experience')) {
                replace();
                window.addEventListener('resize', () => {
                    replace();
                });
            }
        });
        watch(
            () => route.fullPath,
            val => {
                showBottomTip.value =
                    val.includes('blocks-experience') &&
                    !val.includes('mobile');
                if (val.includes('blocks-experience')) {
                    serviceTitle.value = window.document.title =
                        '英荔图形化编程作品平台';
                } else {
                    serviceTitle.value = '英荔互动项目平台';
                }
            },
            {
                immediate: true,
            }
        );
        watch(
            () => store.state.campusInfo,
            val => {
                version.value = val.version;
                showLink.value = !!val.store_id;
                curStoreId.value = val.store_id;
                isFirst.value = !(val.store_id && hasVisitRecord(val.store_id));
            },
            {
                immediate: true,
                deep: true,
            }
        );
        return {
            showLink,
            isFirst,
            showBottomTip,
            campusInfo,
            version,
            serviceTitle,
            curStoreId,
            openCourse,
        };
    },
});
</script>

<style lang="scss">
@import url('./App.scss');

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    padding-top: 96px;
}

.bottom-desc {
    width: 100%;
    position: fixed;
    bottom: 0;
    font-size: 14px;
    text-align: center;
    background: #fff;
    margin-bottom: 0;
    padding: 10px 0;
    color: #999999;
    box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.02),
        0 -2px 4px -1px rgba(0, 0, 0, 0.02);

    p {
        margin: 0;
    }

    .link {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        .course {
            position: relative;
            .tooltip {
                position: absolute;
                top: -50px;
                width: 255px;
                left: -97%;
                .content {
                    position: relative;
                    .arrow {
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 10px solid #252525;
                        top: 30px;
                        left: 45%;
                    }
                    .inner {
                        padding: 8px;
                        border-radius: 6px;
                        background: #252525;
                        color: #fff;
                    }
                }
            }
        }

        .line {
            width: 1px;
            height: 11px;
            background: #e9e9e9;
            margin: 0 10px;
        }
    }

    .course {
        color: var(--font-color-link);
        cursor: pointer;
    }
}

@media screen and (max-width: 600px) {
    #app {
        padding-top: 18.4vw;
    }

    .eliteu-service-vue {
        .customer-service {
            width: 17.333vw;

            .chat {
                width: 17.333vw !important;
            }

            .content {
                width: 70.667vw;
                padding: 14.133vw 5.6vw 4.533vw;
                font-size: 3.733vw;
                color: #999999;
                line-height: 5.867vw;
                text-wrap: auto;

                .text {
                    text-align: justify;
                }

                .qr_code {
                    width: 32vw;
                    height: 32vw;
                }

                .waiter {
                    width: 32vw;
                    position: absolute;
                    top: -14.667vw;
                    right: 3.733vw;
                }

                .wechat {
                    margin-top: 4.533vw;
                }
            }
        }
    }
}
</style>
