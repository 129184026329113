import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import message from 'ant-design-vue/es/message'
import store from '@/store'
import router from '@/router'

interface AxiosResponseType extends AxiosResponse {
  data: {
    msg: string,
    result: [],
    code: number
  }
}


const instance = axios.create({
  baseURL: '/api/v1'
})

const errorHandler = (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 401) {
      store.commit('updateInfo', {
        store_id: null,
        name: '',
        short_name: '',
      })
      router.replace('/blocks-experience/login')
    }
    if (error.response.status === 500) {
      message.error('服务器异常，请稍后再试')
    }
  }
  return Promise.reject(error)
}

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (!navigator.onLine) {
    message.error('服务器异常，请稍后再试')
    return
  }
  if (window.sessionStorage.getItem('store_token') && config.headers) {
    if (config.data?.noNeedToken) {
      config.headers.Authorization = ``
    } else {
      config.headers.Authorization = `Bearer ${window.sessionStorage.getItem('store_token')}`

    }
  }
  return config
}, errorHandler)

instance.interceptors.response.use((response: AxiosResponseType) => {
  return response
}, errorHandler)



export default instance
