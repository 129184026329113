import { ref } from "vue"

export function useVisitRecord() {
    const visitRecord = ref(new Set())
    const get = () => {
        if (localStorage.getItem('visitRecord')) {
            const value = JSON.parse(localStorage.getItem('visitRecord')!);
            if (Array.isArray(value)) {
                visitRecord.value = new Set(value)
            } else {
                visitRecord.value = new Set([])
            }
        } else {
            visitRecord.value = new Set([])
        }
        return visitRecord
    }

    const has = (id: number): boolean => {
        return get().value.has(id)
    }

    const save = (id: number) => {
        const visitRecord = get()
        visitRecord.value.add(id)
        console.log('id', id);
        localStorage.setItem('visitRecord', JSON.stringify(Array.from(visitRecord.value)))
    }
    return {
        get,
        has,
        save
    }
}