import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import Mobile from '../views/Mobile.vue'
import HomeView from '../views/HomeView.vue'
import Category from '../views/Category.vue'
import Works from '../views/Works.vue'
import store from '@/store'
const prefix = '/blocks-experience'

const loginCheck = (next: (param?: string) => void) => {
  if (!store.state.campusInfo.store_id) {
    next(prefix + '/login')
  } else {
    next()
  }
}
const routes: Array<RouteRecordRaw> = [
  {
    path: prefix + '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.state.campusInfo.store_id) {
        next(prefix + '/')
      } else {
        next()
      }
    }
  },
  {
    path: prefix + '/category',
    name: 'category',
    component: Category,
    beforeEnter: (to, from, next) => loginCheck(next)
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: prefix + '/category',
    name: 'category',
    component: Category,
    beforeEnter: (to, from, next) => loginCheck(next)
  },
  {
    path: prefix + '/',
    name: 'index-category',
    redirect: prefix + '/category',
  },
  {
    path: prefix + '/works/:category',
    name: 'works',
    component: Works,
    beforeEnter: (to, from, next) => loginCheck(next)
  },
  {
    path: prefix + '/mobile',
    name: 'mobile',
    component: Mobile
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
