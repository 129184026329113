<template>
    <div
        class="header"
        :style="{ position: headerFixed ? 'fixed' : 'relative' }"
    >
        <div class="container">
            <div class="header-flex">
                <img
                    alt="英荔 AI 创作乐园"
                    class="logo"
                    src="../assets/logo.png"
                />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import store from '@/store';
import { defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'HeaderCom',
    props: {
        name: String,
    },
    setup() {
        const route = useRoute();
        const headerFixed = ref<boolean>(false);
        let _doc: any = document;
        const isFullscreen = ref(
            _doc.fullscreenElement ||
                _doc.msFullscreenElement ||
                _doc.mozFullScreenElement ||
                _doc.webkitFullscreenElement
        );
        const exitFullscreen = () => {
            let el: any;
            el = document.documentElement;
            let _doc: any = document;

            if (
                _doc.fullscreenElement ||
                _doc.msFullscreenElement ||
                _doc.mozFullScreenElement ||
                _doc.webkitFullscreenElement
                    ? true
                    : false
            ) {
                if (_doc.exitFullscreen) {
                    _doc.exitFullscreen();
                } else if (_doc.mozCancelFullScreen) {
                    _doc.mozCancelFullScreen();
                } else if (_doc.webkitCancelFullScreen) {
                    _doc.webkitCancelFullScreen();
                } else if (_doc.msExitFullscreen) {
                    _doc.msExitFullscreen();
                }
            } else {
                if (el.requestFullscreen) {
                    el.requestFullscreen();
                } /*FireFox */ else if (el.mozRequestFullScreen) {
                    el.mozRequestFullScreen();
                } /*Chrome等 */ else if (el.webkitRequestFullScreen) {
                    el.webkitRequestFullScreen();
                } /*IE11*/ else if (el.msRequestFullscreen) {
                    el.msRequestFullscreen();
                }
            }
            isFullscreen.value =
                _doc.fullscreenElement ||
                _doc.msFullscreenElement ||
                _doc.mozFullScreenElement ||
                _doc.webkitFullscreenElement;
            console.log('aaa', isFullscreen);
        };
        watch(
            () => route.name,
            val => {
                let paddingTop = '';
                if (val === 'works') {
                    paddingTop = '0';
                    headerFixed.value = false;
                } else if (val === 'mobile') {
                    paddingTop = '18.4vw';
                    headerFixed.value = true;
                } else {
                    paddingTop = '96px';
                    headerFixed.value = true;
                }
                document
                    .getElementById('app')
                    ?.setAttribute('style', `padding-top:${paddingTop}`);
            },
            {
                immediate: true,
            }
        );
        return {
            isFullscreen,
            exitFullscreen,
            headerFixed,
        };
    },
});
</script>

<style scoped lang="scss">
.header {
    top: 0;
    width: 100%;
    height: 96px;
    background: #fff;
    box-shadow: -4px -13px 11px 4px #080000;
    z-index: 3;

    .container {
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .logo {
            width: 274px;
            height: 53px;
        }
        .header-flex {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
@media screen and (max-width: 600px) {
    .header {
        width: 100vw;
        height: 18.4vw;
        padding-left: 3.2vw;
        .container {
            .logo {
                width: 55.2vw;
                height: 10.95vw;
            }
        }
    }
}
</style>
