import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "login-form" }
const _hoisted_3 = { class: "agree" }
const _hoisted_4 = {
  ref: "video",
  autoplay: "",
  id: "video"
}
const _hoisted_5 = {
  ref: "canvas",
  id: "canvas"
}
const _hoisted_6 = { class: "broswer-tips" }
const _hoisted_7 = { class: "broswer-list" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "name" }
const _hoisted_11 = { class: "desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_spin, {
      size: "large",
      spinning: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("h3", null, [
            _createTextVNode(" 创造乐园门店 "),
            _createElementVNode("br"),
            _createTextVNode(" 图形化编程作品 ")
          ], -1)),
          _createVNode(_component_a_form, {
            model: _ctx.formState,
            name: "basic",
            "label-col": { span: 0 },
            "wrapper-col": { span: 24 },
            autocomplete: "off",
            onFinish: _ctx.onFinish,
            onFinishFailed: _ctx.onFinishFailed,
            class: "form"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                name: "store_no",
                rules: [{ required: true, message: '请输入门店 ID' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.store_no,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.store_no) = $event)),
                    placeholder: "门店 ID",
                    style: { height: '40px', padding: '4px 14px' }
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                name: "store_password",
                rules: [{ required: true, message: '请输入密码' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_password, {
                    placeholder: "密码",
                    value: _ctx.formState.store_password,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.store_password) = $event)),
                    style: { height: '40px', padding: '4px 14px' }
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { "wrapper-col": { offset: 0, span: 24 } }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    "html-type": "submit",
                    style: { width: '100%', height: '40px' },
                    disabled: _ctx.isAgree
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("登录")
                    ])),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }),
              _cache[5] || (_cache[5] = _createElementVNode("p", null, "忘记密码时请联系英荔客服专员", -1)),
              _createVNode(_component_a_form_item, {
                name: "agree",
                "wrapper-col": { offset: 0, span: 24 }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_a_checkbox, {
                      checked: _ctx.formState.agree,
                      "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.agree) = $event))
                    }, null, 8, ["checked"]),
                    _cache[4] || (_cache[4] = _createElementVNode("span", null, "本平台仅限英荔教育集团内部使用。为确保正常运行，会调用计算机的网络适配器、扬声器、麦克风、摄像头、蓝牙设备等并收集相关使用信息。", -1))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "onFinish", "onFinishFailed"])
        ])
      ]),
      _: 1
    }, 8, ["spinning"]),
    _createElementVNode("video", _hoisted_4, null, 512),
    _createElementVNode("canvas", _hoisted_5, null, 512),
    _createVNode(_component_a_modal, {
      open: _ctx.showBroswerTips,
      title: false,
      centered: "",
      footer: null,
      maskClosable: false,
      closable: false,
      width: "557px"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "tip" }, [
            _createTextVNode(" 英荔图形化编程作品平台仅限使用特定的浏览器打开，"),
            _createElementVNode("br"),
            _createTextVNode("请点击下方的浏览器，下载安装 ")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.broswerList, (broswer) => {
              return (_openBlock(), _createElementBlock("div", {
                key: broswer.name,
                class: "broswer"
              }, [
                _createElementVNode("a", {
                  href: broswer.url,
                  target: "_blank"
                }, [
                  _createElementVNode("img", {
                    src: broswer.img,
                    alt: broswer.name
                  }, null, 8, _hoisted_9),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", null, _toDisplayString(broswer.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(broswer.desc), 1)
                ], 8, _hoisted_8)
              ]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["open"])
  ]))
}