import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bottom-desc"
}
const _hoisted_2 = {
  key: 0,
  class: "link"
}
const _hoisted_3 = { class: "course" }
const _hoisted_4 = {
  key: 0,
  class: "tooltip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_EliteuServiceVue3 = _resolveComponent("EliteuServiceVue3")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_router_view),
    _createVNode(_component_EliteuServiceVue3, {
      chatH5Style: { right: '2vw', bottom: '5vw' },
      chatStyle: { bottom: '72px' },
      title: _ctx.serviceTitle
    }, null, 8, ["title"]),
    (_ctx.showBottomTip)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.showLink)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.isFirst)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
                        _createElementVNode("div", { class: "content" }, [
                          _createElementVNode("div", { class: "arrow" }),
                          _createElementVNode("div", {
                            class: "inner",
                            role: "tooltip"
                          }, [
                            _createElementVNode("span", null, "首次使用这个产品吗？先看看教程吧")
                          ])
                        ], -1)
                      ])))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openCourse && _ctx.openCourse(...args))),
                    class: "course"
                  }, "产品入门教程")
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "line" }, null, -1)),
                _createElementVNode("span", null, "产品版本：" + _toDisplayString(_ctx.version), 1)
              ]))
            : _createCommentVNode("", true),
          _cache[3] || (_cache[3] = _createElementVNode("p", null, " 图形化编程作品平台内含大量英荔优秀学员过往的作品改编而成的互动体验游戏， 是英荔「先体验、再改编、后创造、能分享」自驱力学习法的核心体现 ", -1))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}