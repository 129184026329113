import request from '../utils/request'
export const getCategorys = () => {
    return request({
        url: "/scratch_categorys",
        method: "get",
    });
}

export const getCategoryWorks = (params: {
    category_id: number | string
    tag_id?: number | string
}) => {
    return request({
        url: "/scratch_category_works",
        method: "get",
        params
    });
}

export const storeLogin = (data: {
    store_no: string,
    store_password: string
    login_type?: number
}) => {
    return request({
        url: "/store_login/",
        method: "post",
        data
    });
}

export const deviceLoginLog = (data: {
    platform_type: string,
    device_name: string,
    operating_system: string,
    browser: string,
    user_photo: any
}) => {
    return request({
        url: "/device_login_log/",
        method: "post",
        data
    });
}

export const getVersion = (code:  string) => {
    return request({
        url: `/products/${code}/`,
        method: "get",
        data:{
            noNeedToken:true
        }
    });
}
