<template>
    <div class="home">
        <div class="container nav-list">
            <h1>英荔 AI 创造乐园 互动项目</h1>
            <div class="flex">
                <a
                    v-for="(nav, index) in navList"
                    :key="index"
                    :href="nav.href"
                >
                    <div class="content">
                        <img :src="nav.cover" alt="" />
                        <div class="title">{{ nav.title }}</div>
                    </div>
                    <img class="bg" src="../assets/images/blueBg.png" alt="" />
                </a>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
const p1 = require('@/assets/images/1.png');
const p2 = require('@/assets/images/2.png');
const p3 = require('@/assets/images/3.png');
export default defineComponent({
    name: 'HomeView',
    setup() {
        const origin = window.location.origin;
        const aiShowUrl = origin.includes('.xyz')
            ? 'https://aishow.eliteu.xyz/'
            : 'https://aishow.aimaker.space/';
        const navList = [
            {
                href: aiShowUrl,
                cover: p1,
                title: 'AI 互动游戏',
            },
            {
                href: `${origin}/blocks-experience/category`,
                cover: p2,
                title: '图形化编程作品',
            },
            {
                href: origin.includes('.space')
                    ? `https://hdty-wall.aimaker.space/`
                    : `https://hdty-wall.eliteu.xyz/`,
                cover: p3,
                title: '互动投影（墙面版）',
            },
        ];
        return {
            aiShowUrl,
            navList,
        };
    },
});
</script>
<style lang="scss" scoped>
.nav-list {
    text-align: center;
    padding-top: 97px;

    h1 {
        font-weight: 600 !important;
    }

    .flex {
        margin-top: 75px;
        display: flex;
        justify-content: center;

        a {
            width: 312px;
            height: 310px;
            margin: 0 16px;
            position: relative;
            &:hover {
                .title {
                    color: #0084ff !important;
                }
                .content {
                    top: -10px;
                }
            }

            .bg {
                width: 262px;
                position: absolute;
                left: 50%;
                margin-left: -131px;
                z-index: 1;
                bottom: -30px;
            }

            .content {
                position: absolute;
                border-radius: 10px;
                overflow: hidden;
                z-index: 2;
                .title {
                    padding: 16px;
                    background: #fff;
                    color: #333333;
                    font-size: 14px;
                    color: #333333;
                    text-align: left;
                }

                img {
                    width: 100%;
                    height: 246px;
                    object-fit: contain;
                }
            }
        }
    }
}
</style>
