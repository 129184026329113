// webpack --devtool source-map --entry ./analytics.full.js --output-path ./ --output-filename analytics.js
// 添加浏览器toast
export function BrowserType() {
  // 权重：系统 + 系统版本 > 平台 > 内核 + 载体 + 内核版本 + 载体版本 > 外壳 + 外壳版本
  const ua = navigator.userAgent.toLowerCase();
  const testUa = regexp => regexp.test(ua);
  const testVs = regexp =>
    ua
      .match(regexp)
      .toString()
      .replace(/[^0-9|_.]/g, '')
      .replace(/_/g, '.');
  // 系统
  let system = 'unknow';
  if (testUa(/windows|win32|win64|wow32|wow64/g)) {
    system = 'windows'; // windows系统
  } else if (testUa(/macintosh|macintel/g)) {
    system = 'macos'; // macos系统
  } else if (testUa(/x11/g)) {
    system = 'linux'; // linux系统
  } else if (testUa(/android|adr/g)) {
    system = 'android'; // android系统
  } else if (testUa(/ios|iphone|ipad|ipod|iwatch/g)) {
    system = 'ios'; // ios系统
  }
  // 系统版本
  let systemVs = 'unknow';
  if (system === 'windows') {
    if (testUa(/windows nt 5.0|windows 2000/g)) {
      systemVs = '2000';
    } else if (testUa(/windows nt 5.1|windows xp/g)) {
      systemVs = 'xp';
    } else if (testUa(/windows nt 5.2|windows 2003/g)) {
      systemVs = '2003';
    } else if (testUa(/windows nt 6.0|windows vista/g)) {
      systemVs = 'vista';
    } else if (testUa(/windows nt 6.1|windows 7/g)) {
      systemVs = '7';
    } else if (testUa(/windows nt 6.2|windows 8/g)) {
      systemVs = '8';
    } else if (testUa(/windows nt 6.3|windows 8.1/g)) {
      systemVs = '8.1';
    } else if (testUa(/windows nt 10.0|windows 10/g)) {
      systemVs = '10';
    }
  } else if (system === 'macos') {
    systemVs = testVs(/os x [\d._]+/g);
  } else if (system === 'android') {
    systemVs = testVs(/android [\d._]+/g);
  } else if (system === 'ios') {
    systemVs = testVs(/os [\d._]+/g);
  }
  // 平台
  let platform = 'unknow';
  if (system === 'windows' || system === 'macos' || system === 'linux') {
    platform = 'desktop'; // 桌面端
  } else if (system === 'android' || system === 'ios' || testUa(/mobile/g)) {
    platform = 'mobile'; // 移动端
  }
  // 内核和载体
  let engine = 'unknow';
  let supporter = 'unknow';
  if (testUa(/applewebkit/g)) {
    engine = 'webkit'; // webkit内核
    if (testUa(/edge/g)) {
      supporter = 'edge'; // edge浏览器
    } else if (testUa(/opr/g)) {
      supporter = 'opera'; // opera浏览器
    } else if (testUa(/chrome/g)) {
      supporter = 'chrome'; // chrome浏览器
    } else if (testUa(/safari/g)) {
      supporter = 'safari'; // safari浏览器
    }
  } else if (testUa(/gecko/g) && testUa(/firefox/g)) {
    engine = 'gecko'; // gecko内核
    supporter = 'firefox'; // firefox浏览器
  } else if (testUa(/presto/g)) {
    engine = 'presto'; // presto内核
    supporter = 'opera'; // opera浏览器
  } else if (testUa(/trident|compatible|msie/g)) {
    engine = 'trident'; // trident内核
    supporter = 'iexplore'; // iexplore浏览器
  }
  // 内核版本
  let engineVs = 'unknow';
  if (engine === 'webkit') {
    engineVs = testVs(/applewebkit\/[\d._]+/g);
  } else if (engine === 'gecko') {
    engineVs = testVs(/gecko\/[\d._]+/g);
  } else if (engine === 'presto') {
    engineVs = testVs(/presto\/[\d._]+/g);
  } else if (engine === 'trident') {
    engineVs = testVs(/trident\/[\d._]+/g);
  }
  // 载体版本
  let supporterVs = 'unknow';
  if (supporter === 'chrome') {
    supporterVs = testVs(/chrome\/[\d._]+/g);
  } else if (supporter === 'safari') {
    supporterVs = testVs(/version\/[\d._]+/g);
  } else if (supporter === 'firefox') {
    supporterVs = testVs(/firefox\/[\d._]+/g);
  } else if (supporter === 'opera') {
    supporterVs = testVs(/opr\/[\d._]+/g);
  } else if (supporter === 'iexplore') {
    supporterVs = testVs(/(msie [\d._]+)|(rv:[\d._]+)/g);
  } else if (supporter === 'edge') {
    supporterVs = testVs(/edge\/[\d._]+/g);
  }
  // 外壳和外壳版本
  let shell = 'none';
  let shellVs = 'unknow';
  if (testUa(/micromessenger/g)) {
    shell = 'wechat'; // 微信浏览器
    shellVs = testVs(/micromessenger\/[\d._]+/g);
  } else if (testUa(/qqbrowser/g)) {
    shell = 'qq'; // QQ浏览器
    shellVs = testVs(/qqbrowser\/[\d._]+/g);
  } else if (testUa(/ucbrowser/g)) {
    shell = 'uc'; // UC浏览器
    shellVs = testVs(/ucbrowser\/[\d._]+/g);
  } else if (testUa(/qihu 360se/g)) {
    shell = '360'; // 360浏览器(无版本)
  } else if (testUa(/2345explorer/g)) {
    shell = '2345'; // 2345浏览器
    shellVs = testVs(/2345explorer\/[\d._]+/g);
  } else if (testUa(/metasr/g)) {
    shell = 'sougou'; // 搜狗浏览器(无版本)
  } else if (testUa(/lbbrowser/g)) {
    shell = 'liebao'; // 猎豹浏览器(无版本)
  } else if (testUa(/maxthon/g)) {
    shell = 'maxthon'; // 遨游浏览器
    shellVs = testVs(/maxthon\/[\d._]+/g);
  }
  return Object.assign(
    {
      engine, // webkit gecko presto trident
      engineVs,
      platform, // desktop mobile
      supporter, // chrome safari firefox opera iexplore edge
      supporterVs,
      system, // windows macos linux android ios
      systemVs
    },
    shell === 'none'
      ? {}
      : {
          shell, // wechat qq uc 360 2345 sougou liebao maxthon
          shellVs
        }
  );
}

// (function () {
//   ready(function () {
//     let style = document.createElement('style');
//     style.innerHTML = `
//     #snackbar {
//       white-space: nowrap;
//       border-radius: 4px;
//       visibility: hidden;
//       background-color: white;
//       color: rgba(0, 0, 0, 0.65);
//       text-align: center;
//       border-radius: 2px;
//       padding: 10px 16px;
//       position: fixed;
//       z-index: 99999;
//       left: 50%;
//       transform: translateX(-50%);
//       top: 70px;
//       font-size: 14px;
//       box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
//         0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
//     }

//     #snackbar.show {
//       visibility: visible;
//       -webkit-animation: fadein 0.5s;
//       animation: fadein 0.5s;
//     }
//     #snackbar a {
//       text-decoration: none;
//       color: #376fb3;
//     }
//     #snackbar button {
//       cursor: pointer;
//       border: none;
//       background: transparent;
//       outline: none;
//     }
//     #snackbar button span {
//       color: rgba(0,0,0,0.45);
//       position: relative;
//       top: 2px;
//     }
//     @-webkit-keyframes fadein {
//       from {
//         top: 0;
//         opacity: 0;
//       }
//       to {
//         top: 30px;
//         opacity: 1;
//       }
//     }

//     @keyframes fadein {
//       from {
//         top: 0;
//         opacity: 0;
//       }
//       to {
//         top: 30px;
//         opacity: 1;
//       }
//     }

//     @-webkit-keyframes fadeout {
//       from {
//         top: 30px;
//         opacity: 1;
//       }
//       to {
//         top: 0;
//         opacity: 0;
//       }
//     }

//     @keyframes fadeout {
//       from {
//         top: 30px;
//         opacity: 1;
//       }
//       to {
//         top: 0;
//         opacity: 0;
//       }
//     }
//       `;
//     document.head.appendChild(style);
//     let res = BrowserType();
//     console.log(JSON.stringify(res));

//     if (
//       res.supporter === 'chrome' &&
//       Number(res.supporterVs.split('.')[0]) >= 76
//       // ||
//       // res.supporter === 'firefox' ||
//       // res.platform === 'mobile'
//     ) {
//       console.log('browser check ok!');
//     } else {
//       if (!window.location.pathname.includes('/login')) return;
//       let downloadurl;
//       let toast = document.createElement('div');
//       // if (res.system === "macos") {
//       //   downloadurl =
//       //     "http://scratch3-files.just4fun.site/googlechrome.dmg";
//       // } else {
//       //   downloadurl = "http://scratch3-files.just4fun.site/ChromeSetup.exe";
//       // }
//       downloadurl = 'https://www.google.cn/intl/zh-CN/chrome/';
//       toast.id = 'snackbar';
//       toast.className = 'show';

//       toast.innerHTML = `为保证你在平台上的体验，请使用新版谷歌浏览器打开此网页。<a href="${downloadurl}">点击下载</a> <button onclick="TOASTclearToast()"><span><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></span></button>`;
//       document.body.appendChild(toast);
//       console.log('toast append ok!');
//     }
//   });

//   // function ready(fn) {
//   //   if (document.addEventListener) {
//   //     //标准浏览器
//   //     document.addEventListener(
//   //       'DOMContentLoaded',
//   //       function () {
//   //         //注销时间，避免反复触发
//   //         document.removeEventListener(
//   //           'DOMContentLoaded',
//   //           arguments.callee,
//   //           false
//   //         );
//   //         fn(); //执行函数
//   //       },
//   //       false
//   //     );
//   //   } else if (document.attachEvent) {
//   //     //IE浏览器
//   //     document.attachEvent('onreadystatechange', function () {
//   //       if (document.readyState === 'complete') {
//   //         document.detachEvent('onreadystatechange', arguments.callee);
//   //         fn(); //函数执行
//   //       }
//   //     });
//   //   }
//   // }
//   function ready(fn) {
//     if (document.addEventListener) {
//       //标准浏览器
//       document.addEventListener(
//         'DOMContentLoaded',
//         function fn1() {
//           //注销时间，避免反复触发
//           document.removeEventListener('DOMContentLoaded', fn1, false); // false 事件在冒泡阶段执行
//           fn(); //执行函数
//         },
//         false
//       );
//     } else if (document.attachEvent) {
//       //IE浏览器
//       document.attachEvent('onreadystatechange', function fn2() {
//         if (document.readyState === 'complete') {
//           document.detachEvent('onreadystatechange', fn2);
//           fn(); //函数执行
//         }
//       });
//     }
//   }
// })();

// window.TOASTclearToast = function () {
//   var x = document.getElementById('snackbar');
//   x.className = x.className.replace('show', '');
// };
